import {
    CreateOrUpdateFeeConfigurationDto,
    FeeConfigurationValueEntranceType,
    useGetAllTypeInvestisseursForFormQuery,
    useGetFeeConfigurationByIdQuery,
    useUpdateFeeConfigurationsByFeeIdMutation,
} from '@api/api';
import TitlePopUpForm from '@components/commun/formComponent/LayoutComponents/TitlePopUpForm';
import { transformDate } from '@utils/Utils';
import Formula from 'fparser';
import React, { useEffect } from 'react';
import { Control, useForm, UseFormRegister } from 'react-hook-form';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useGetAllFondsQuery } from '../../../redux/features/fondsSlice';
import { useNotificationContext } from '../../Context/notification-context';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import FieldArray from '../../commun/formComponent/FieldArray';
import InputComponent from '../../commun/formComponent/InputComponent';
import RemoveFieldArrayComponent from '../../commun/formComponent/RemoveFieldArrayComponent';
import SelectComponent from '../../commun/formComponent/SelectComponent';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Form {
    items: CreateOrUpdateFeeConfigurationDto[];
}

const UpdateFeeConfigurations: React.FC<Props> = ({ open, setOpen }) => {
    const { showError, showSuccess } = useNotificationContext();

    const [feeIdParam, setFeeIdParam] = useQueryParam('feeId', NumberParam);

    const { data: fee, isLoading } = useGetFeeConfigurationByIdQuery(
        feeIdParam ?? 0,
        {
            skip: !open || !feeIdParam,
        }
    );

    const { data: fonds } = useGetAllFondsQuery();

    const { data: typeInvestisseurs, isLoading: isLoadingTypeInvestisseur } =
        useGetAllTypeInvestisseursForFormQuery();

    const [updateFeeConfigurations, { isLoading: isLoadingAdd }] =
        useUpdateFeeConfigurationsByFeeIdMutation();

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { isValid },
    } = useForm<Form>();

    useEffect(() => {
        if (!fee) return;
        reset({
            items:
                fee.feeConfigurations.map((f) => {
                    return {
                        id: f.id,
                        date: transformDate(new Date(f.date!).getTime())
                            .toJSON()
                            ?.split('T')[0],
                        feeValue: f.feeValue,
                        fondsId: f.fondsId,
                        feeId: f.feeId,
                        typeInvestisseurId: f.typeInvestisseurId,
                    };
                }) ?? [],
        });
    }, [fee, reset, feeIdParam]);

    const onSubmit = async (data: Form) => {
        const dataToSend: CreateOrUpdateFeeConfigurationDto[] = data.items.map(
            (d) => {
                return {
                    ...d,
                    date: new Date(d.date)?.toISOString(),
                };
            }
        );

        if (feeIdParam === 2) {
            // test formula
            let formulaValid = true;
            dataToSend.forEach((d) => {
                const formula = (
                    d.feeValue as FeeConfigurationValueEntranceType
                )?.formula;
                if (formula) {
                    try {
                        new Formula(formula);
                    } catch (e) {
                        showError(
                            'Error',
                            `Formula is not valid for investisseur: ${typeInvestisseurs?.find((t) => t.id == d.typeInvestisseurId)?.name}, formule: ${formula}`
                        );
                        formulaValid = false;
                    }
                }
            });
            if (!formulaValid) return;
        }

        await updateFeeConfigurations({
            feeId: feeIdParam!,
            updateFeeConfigurationsRequest: {
                feeId: feeIdParam!,
                data: dataToSend,
            },
        })
            .unwrap()
            .then(() => {
                showSuccess('Created', 'Fee configurations updated');
                setOpen(false);
            })
            .catch((e: any) => {
                showError('Error', e.data?.message);
            });
    };

    return (
        <PopUp
            title={() => {
                return (
                    <>
                        <TitlePopUpForm>
                            Edit fee configurations for {fee?.name}
                        </TitlePopUpForm>
                    </>
                );
            }}
            open={true}
            setOpen={setOpen}
            buttonBoolean={false}
            width="md:w-11/12"
        >
            {isLoading || isLoadingTypeInvestisseur ? (
                <Loading />
            ) : (
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formTemplateSingUp"
                >
                    <div
                        className={`grid font-semibold text-md ${feeIdParam === 2 ? 'grid-cols-[1fr,1fr,1fr,auto]' : 'grid-cols-[1fr,1fr,1fr,1fr,auto]'}  w-full`}
                    >
                        {feeIdParam !== 2 ? (
                            <>
                                <p>Fonds</p>
                            </>
                        ) : null}
                        <p>Type Investisseur</p>
                        <DisplayHeader feeId={feeIdParam!} />
                        <p>Date</p>
                        <p></p>
                    </div>
                    <FieldArray
                        control={control}
                        className="w-full"
                        name="items"
                    >
                        {({ fields, append, remove }) => (
                            <>
                                <div className="flex flex-col w-full mb-2">
                                    {fields.map((field, index) => (
                                        <div
                                            key={field.id}
                                            className={`grid ${feeIdParam === 2 ? 'grid-cols-[1fr,1fr,1fr,auto]' : 'grid-cols-[1fr,1fr,1fr,1fr,auto]'} w-full gap-x-2 relative`}
                                        >
                                            {feeIdParam !== 2 ? (
                                                <>
                                                    {' '}
                                                    <SelectComponent
                                                        register={register}
                                                        value={`items.${index}.fondsId`}
                                                        container={true}
                                                        optionValues={
                                                            fonds?.map(
                                                                (val) => {
                                                                    return {
                                                                        value: val.id,
                                                                        label: val.name,
                                                                    };
                                                                }
                                                            ) ?? []
                                                        }
                                                        control={control}
                                                    ></SelectComponent>
                                                </>
                                            ) : null}

                                            <SelectComponent
                                                register={register}
                                                value={`items.${index}.typeInvestisseurId`}
                                                container={true}
                                                optionValues={
                                                    typeInvestisseurs?.map(
                                                        (val) => {
                                                            return {
                                                                value: val.id,
                                                                label: val.name,
                                                            };
                                                        }
                                                    ) ?? []
                                                }
                                                control={control}
                                            ></SelectComponent>

                                            <DisplayForm
                                                feeId={feeIdParam!}
                                                index={index}
                                                control={control}
                                                register={register}
                                            />

                                            <InputComponent
                                                register={register}
                                                type="date"
                                                value={`items.${index}.date`}

                                                // className={{ input: "row-span-1 col-span-1" }}
                                            ></InputComponent>

                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <PrimaryButton
                                    className=" mx-auto mb-2"
                                    onClick={() =>
                                        append({
                                            date: new Date()
                                                .toJSON()
                                                ?.split('T')[0],

                                            fondsId: undefined,
                                            feeId: feeIdParam,
                                            // projectId: project.current?.id!,
                                        } as any)
                                    }
                                >
                                    Ajouter
                                </PrimaryButton>
                            </>
                        )}
                    </FieldArray>

                    <div className="mt-5 w-full flex justify-center items-center gap-3 text-center">
                        <WhiteButton
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </WhiteButton>
                        <PrimaryButton loading={isLoadingAdd} type="submit">
                            Edit
                        </PrimaryButton>
                    </div>
                </form>
            )}
        </PopUp>
    );
};

export default UpdateFeeConfigurations;

function DisplayHeader({ feeId }: { feeId: number }) {
    return (
        <>
            {feeId === 11 ? (
                <div className=" grid grid-cols-[1fr,1fr,1fr] gap-x-2">
                    <p>Seniority</p>
                    <p>Value</p>
                    <p></p>
                </div>
            ) : feeId === 2 ? (
                <p>Formula</p>
            ) : (
                <p>Value</p>
            )}
        </>
    );
}

function DisplayForm({
    feeId,
    index,
    control,
    register,
}: {
    feeId: number;
    index: number;
    control: Control<Form, any>;
    register: UseFormRegister<Form>;
}) {
    return (
        <>
            {feeId === 11 ? (
                <>
                    <FieldArray
                        control={control}
                        name={`items.${index}.feeValue.values`}
                    >
                        {({
                            fields: feeValueFields,
                            append: feeValueAppend,
                            remove: feeValueRemove,
                        }) => (
                            <>
                                {feeValueFields.map((feeValuefield, index2) => (
                                    <div
                                        key={feeValuefield.id}
                                        className="grid grid-cols-[1fr,1fr,auto] gap-x-2"
                                    >
                                        <InputComponent
                                            register={register}
                                            type="number"
                                            required={false}
                                            value={`items.${index}.feeValue.values.${index2}.seniority`}
                                        ></InputComponent>

                                        <InputComponent
                                            register={register}
                                            type="number"
                                            required={false}
                                            value={`items.${index}.feeValue.values.${index2}.value`}
                                        ></InputComponent>

                                        <RemoveFieldArrayComponent
                                            remove={feeValueRemove}
                                            index={index}
                                        />
                                    </div>
                                ))}

                                <PrimaryButton
                                    className=" mx-auto mb-2"
                                    onClick={() =>
                                        feeValueAppend({
                                            seniority: 0,
                                            value: 0,

                                            // projectId: project.current?.id!,
                                        })
                                    }
                                >
                                    Ajouter
                                </PrimaryButton>
                            </>
                        )}
                    </FieldArray>
                </>
            ) : feeId === 2 ? (
                <InputComponent
                    register={register}
                    value={`items.${index}.feeValue.formula`}
                />
            ) : (
                <InputComponent
                    register={register}
                    type="number"
                    required={false}
                    value={`items.${index}.feeValue.value`}
                ></InputComponent>
            )}
        </>
    );
}
