import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ComputationAdmin } from '../../../../function/ComputationAdmin';
import { setDate } from '../../../../function/Utils';
import { typeFiltration } from '../../../../types/BackOffice';

import {
    useGetUsersWithSharesByDateLimitQuery,
    UserWithSharesDto,
} from '@api/api';
import Loading from '../../../commun/Loading';
import {
    BackOfficeState,
    RootBlackOfficeState,
} from '../../../Context/AppContext';
import ElementTableUser from '../Components/ElementTableUser';
import FilterApparition from '../Components/FilterApparition';
import FiltrationColumnTable, {
    filtrationInterface,
    typeOrderOrFiltration,
} from '../Components/FiltrationColumnTable';
import ElementNotComputedComponent from '../ElementNotComputedComponent';

const ViewTableUsers = () => {
    const navigate = useNavigate();

    const [totalInFonds, setTotalInFonds] = useState<number>(0);

    const backOfficeState = useContext(BackOfficeState);

    const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
    const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);

    const classNameObejct = {
        head: 'text-sm text-gray-900 px-6 py-4 text-left font-semibold text-right',
        item: 'text-sm font-light px-6 py-4 whitespace-nowrap text-right',
    };

    const [filterColumn, setFilterColumn] = useState<
        filtrationInterface<UserWithSharesDto>[]
    >([]);

    const [isPending, setIsPending] = useState<
        'pending' | 'shareComputed' | 'shareNotComputed'
    >('pending');

    const rootState = useContext(RootBlackOfficeState);

    const [dateChosen, setDateChosen] = useState<Date | undefined>(undefined);

    useEffect(() => {
        if (
            backOfficeState?.day &&
            backOfficeState?.year &&
            backOfficeState?.month !== undefined
        ) {
            setDateChosen(
                setDate(
                    backOfficeState?.year!,
                    backOfficeState?.month!,
                    backOfficeState?.day!
                )
            );
        }
    }, [
        backOfficeState?.day && backOfficeState?.day,
        backOfficeState?.year && backOfficeState?.year,
        backOfficeState?.month && backOfficeState?.month,
    ]);

    const [search, setSearch] = useState<string>('');

    // TODO : Use query params for nbItemsShowed and startIntervalNbItems
    // If nbItemsShowed or startIntervalNbItems change, we need to update the query params and fetch the data again

    const {
        data: userWithSharesDataWithNbItems,
        isLoading,
        isFetching,
    } = useGetUsersWithSharesByDateLimitQuery(
        {
            date: (dateChosen ? dateChosen : new Date())?.toISOString(),
            fondsId: backOfficeState?.fondsId!,
            search: search,
            limit: nbItemsShowed,
            offset: startIntervalNbItems,
        },
        {
            skip:
                dateChosen === undefined ||
                backOfficeState?.fondsId === undefined
                    ? true
                    : false || nbItemsShowed > 0
                      ? false
                      : true,
        }
    );

    const userWithSharesData = userWithSharesDataWithNbItems?.rows;
    const nbItems = userWithSharesDataWithNbItems?.count;
    const firstDate = userWithSharesDataWithNbItems?.firstDate;

    const [computationAdminObject, setComputationAdminObject] = useState<
        ComputationAdmin | undefined
    >();
    useEffect(() => {
        if (userWithSharesData) {
            setComputationAdminObject(
                new ComputationAdmin(userWithSharesData, {
                    date: firstDate,
                    fondsId: backOfficeState?.fondsId!,
                })
            );
        }
    }, [userWithSharesData]);

    useEffect(() => {
        if (search !== '') return;
        if (computationAdminObject && dateChosen) {
            const bool =
                computationAdminObject?.getLastShareComputed(dateChosen)
                    ?.shareComputed ?? false;
            if (bool) {
                setIsPending('shareComputed');
            } else {
                setIsPending('shareNotComputed');
            }
        }
    }, [computationAdminObject, dateChosen]);

    return (
        <React.Fragment>
            <FilterApparition
                startIntervalNbItems={startIntervalNbItems}
                setNbItemsShowed={setNbItemsShowed}
                nbItemsShowed={nbItemsShowed}
                setStartIntervalNbItems={setStartIntervalNbItems}
                array={userWithSharesData ?? []}
                offset={true}
                nbItems={nbItems ?? 0}
            />
            <div className="flex flex-col max-w-full overflow-x-auto">
                <div className="overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full">
                    <div className="py-2 inline-block min-w-full">
                        <div className="overflow-hidden">
                            {/* <p>
                    Valeur total du fonds{" "}
                    {printLargeValue(totalInFonds.toFixed(2))}
                  </p> */}
                            <table className="min-w-full">
                                <thead className="bg-white border-b border-t">
                                    <tr>
                                        <th
                                            scope="col"
                                            className={`${classNameObejct.head} `}
                                        >
                                            #
                                        </th>

                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Date premier
                                            <br />
                                            investissement
                                        </th>
                                        <FiltrationColumnTable
                                            element={'lastName'}
                                            setSearch={setSearch}
                                            type={
                                                typeFiltration.firstName_Surname
                                            }
                                            setFilterColumn={setFilterColumn}
                                            columnName={() => (
                                                <p className="inline-block">
                                                    Nom <br /> Prénom
                                                </p>
                                            )}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.filter
                                            }
                                            textLeft={true}
                                        />
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Montant
                                            <br /> investi
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            nombre <br /> de parts
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Valeur <br /> nominale
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Valeur de <br /> la part
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Performance
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            TRI
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Valeur nette du
                                            <br /> portefeuille
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Proportion <br /> bloquée
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Date de la <br /> valorisation
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isPending === 'pending' ||
                                    isLoading ||
                                    isFetching ? (
                                        <tr className="w-full mx-auto">
                                            <td
                                                colSpan={11}
                                                className="text-center text-gray-500 text-xl  pt-10"
                                            >
                                                <Loading />
                                            </td>
                                        </tr>
                                    ) : isPending === 'shareComputed' ? (
                                        <>
                                            {!!(
                                                backOfficeState?.fondsId !==
                                                undefined
                                            ) && (
                                                <React.Fragment>
                                                    {computationAdminObject !==
                                                        undefined &&
                                                        computationAdminObject.compurationUser &&
                                                        dateChosen && (
                                                            <>
                                                                {computationAdminObject
                                                                    .compurationUser
                                                                    .length ===
                                                                0 ? (
                                                                    <tr className="w-full mx-auto">
                                                                        <td
                                                                            colSpan={
                                                                                11
                                                                            }
                                                                            className="text-center text-gray-500 text-xl  pt-10"
                                                                        >
                                                                            Aucun
                                                                            utilisateur
                                                                            touvée
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        {computationAdminObject.compurationUser
                                                                            // .filter(
                                                                            //   (val, index) =>
                                                                            //     index >= startIntervalNbItems &&
                                                                            //     index <
                                                                            //       startIntervalNbItems +
                                                                            //         nbItemsShowed &&
                                                                            //     val.isDateComputed(dateChosen)
                                                                            // )
                                                                            .map(
                                                                                (
                                                                                    userComputation,
                                                                                    key
                                                                                ) =>
                                                                                    userComputation.getAggragateShareComninedInFunds(
                                                                                        setDate(
                                                                                            backOfficeState?.year!,
                                                                                            backOfficeState?.month!,
                                                                                            backOfficeState?.day!
                                                                                        ),
                                                                                        backOfficeState?.fondsId!
                                                                                    )
                                                                            )
                                                                            .filter(
                                                                                (
                                                                                    fondsInfo
                                                                                ) =>
                                                                                    fondsInfo &&
                                                                                    fondsInfo.infoCombined
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    fondsInfo,
                                                                                    key
                                                                                ) => (
                                                                                    <ElementTableUser
                                                                                        key={
                                                                                            key
                                                                                        }
                                                                                        offset={
                                                                                            startIntervalNbItems
                                                                                        }
                                                                                        fondsInfo={
                                                                                            fondsInfo!
                                                                                        }
                                                                                        index1={
                                                                                            key
                                                                                        }
                                                                                        dateChosen={
                                                                                            dateChosen
                                                                                        }
                                                                                    />
                                                                                )
                                                                            )}
                                                                    </React.Fragment>
                                                                )}
                                                            </>
                                                        )}
                                                </React.Fragment>
                                            )}
                                        </>
                                    ) : (
                                        <tr className="w-full mx-auto">
                                            <td
                                                colSpan={11}
                                                className="text-center text-gray-500 text-xl  pt-10"
                                            >
                                                <ElementNotComputedComponent element="Les parts" />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ViewTableUsers;
