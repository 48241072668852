import { ChevronRightIcon } from '@heroicons/react/outline';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import {
    BooleanParam,
    NumberParam,
    StringParam,
    useQueryParam,
} from 'use-query-params';
import {
    compareTwoDate,
    filterFunctions,
    formatDate,
    getInterest,
    isDateClosed,
    nextDay,
    printLargeValue,
    setDate,
    stringToFloat,
} from '../../../../function/Utils';

import { useAppDispatch } from '../../../../redux/store';
import { typeFiltration } from '../../../../types/BackOffice';

import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import CheckboxComponent from '../../../commun/formComponent/CheckboxComponent';
import InputComponent from '../../../commun/formComponent/InputComponent';
import Loading from '../../../commun/Loading';
import PopUp from '../../../commun/PopUp';
import { BackOfficeState } from '../../../Context/AppContext';
import { useNotificationContext } from '../../../Context/notification-context';
import FilterApparition from '../Components/FilterApparition';
import FiltrationColumnTable, {
    filtrationInterface,
    typeOrderOrFiltration,
} from '../Components/FiltrationColumnTable';
import ElementNotComputedComponent from '../ElementNotComputedComponent';

import {
    ComputationUserDashboard,
    IFondsInfoUser,
} from '../../../../function/ComputationUserDashboard';
import { useGetAllFondsQuery } from '../../../../redux/features/fondsSlice';

import {
    CreateTransactionDto,
    FilterClosure,
    ProjectTableClosureDto,
    ProjectWithValorisationDto,
    TransactionStatus,
    TypeTransaction,
    UpdateProjectClosureDto,
    useCreateTransactionMutation,
    useGetAllPlatformsQuery,
    useGetCashByFondsIdBetweenDatesQuery,
    useGetCashIdsQuery,
    useGetProjectByIdQuery,
    useGetProjectsClosureFilterQuery,
    useGetProjectsWithValorisationsQuery,
    useGetSharesByDateForUserFondsQuery,
    useGetTransactionsProjectQuery,
    useGetValorisationByProjectQuery,
    useUpdateClosureProjectMutation,
} from '@api/api';
import { DropDownComponentAdmin } from '../../../commun/DropDownComponent';
import TableComponent from '../Components/TableComponent';

interface Props {
    editProjectId?: number;
    addProject?: true;
}

const ViewTableProjects: React.FC<Props> = ({ editProjectId, addProject }) => {
    const navigate = useNavigate();

    const { data: platformsData } = useGetAllPlatformsQuery();

    const backOfficeState = useContext(BackOfficeState);

    const { data: fondsData } = useGetAllFondsQuery();

    const classNameObejct = {
        head: 'text-sm text-gray-900 px-6 py-4 text-left font-semibold',
        item: 'text-sm font-light px-6 py-4 whitespace-nowrap',
    };

    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(50);
    const [filterColumn, setFilterColumn] = useState<
        filtrationInterface<ProjectWithValorisationDto>[]
    >([
        {
            element: 'dateStart',
            type: typeFiltration.date,
            value: 'decroissant',
            orderOrFiltration: typeOrderOrFiltration.order,
        },
    ]);

    const [returnQuery, setReturnQuery] = useQueryParam(
        'returnBackoffice',
        BooleanParam
    );

    const [dateChosen, setDateChosen] = useState<Date | undefined>(undefined);

    useEffect(() => {
        if (
            backOfficeState?.day &&
            backOfficeState?.year &&
            backOfficeState?.month !== undefined
        ) {
            setDateChosen(
                setDate(
                    backOfficeState?.year!,
                    backOfficeState?.month!,
                    backOfficeState?.day!
                )
            );
        }
    }, [
        backOfficeState?.day && backOfficeState?.day,
        backOfficeState?.year && backOfficeState?.year,
        backOfficeState?.month && backOfficeState?.month,
    ]);

    // TODO : Use query params for nbItemsShowed and startIntervalNbItems
    // If nbItemsShowed or startIntervalNbItems change, we need to update the query params and fetch the data again
    const [isPending, setIsPending] = useState<
        'pending' | 'shareComputed' | 'shareNotComputed'
    >('pending');

    const userFondsId =
        fondsData?.find((f) => f.id === backOfficeState?.fondsId)?.userId ||
        undefined;

    const { data: shareDataFull, isLoading } =
        useGetSharesByDateForUserFondsQuery(
            {
                userId: userFondsId ?? 0,
                dateStart: dateChosen
                    ? dateChosen?.toISOString()
                    : new Date()?.toISOString(),
            },
            {
                skip:
                    fondsData === undefined ||
                    userFondsId === undefined ||
                    dateChosen === undefined ||
                    (backOfficeState?.fondsId === undefined ? true : false),
            }
        );

    const shareData = shareDataFull?.shares;

    const [ShareDataFiltered, setShareDataFiltered] = useState<
        ComputationUserDashboard | undefined
    >();

    useEffect(() => {
        if (
            shareData &&
            shareData?.length > 0 &&
            backOfficeState &&
            userFondsId !== undefined
        ) {
            const computationObject: ComputationUserDashboard =
                new ComputationUserDashboard(
                    shareData,
                    userFondsId!,
                    shareDataFull?.firstDates || []
                );

            setShareDataFiltered(computationObject);
        }
    }, [filterColumn, shareData, backOfficeState, backOfficeState?.fondsId]);

    const [searchProjectName, setSearchProjectName] = useState<string>('');
    const [searchPlatformName, setSearchPlatformName] = useState<string>('');

    const {
        data: cashData,
        isLoading: isLoadingCash,
        isError: isErrorCash,
        isSuccess: isSuccessCash,
    } = useGetCashByFondsIdBetweenDatesQuery(
        {
            dateStart: (dateChosen ? dateChosen : new Date())?.toISOString(),
            dateEnd: (dateChosen
                ? nextDay(dateChosen)
                : new Date()
            )?.toISOString(),
            fondsId: backOfficeState?.fondsId!,
        },
        {
            skip:
                dateChosen === undefined ||
                backOfficeState?.fondsId === undefined
                    ? true
                    : false,
        }
    );

    const {
        data: projectValorisationsWithNbItems,
        isLoading: isLoadingValo,
        isFetching: isFetchingValo,
        isError: isErrorValo,
        isSuccess: isSuccessValo,
    } = useGetProjectsWithValorisationsQuery(
        {
            dateStart: (dateChosen ? dateChosen : new Date())?.toISOString(),
            dateEnd: (dateChosen
                ? nextDay(dateChosen)
                : new Date()
            )?.toISOString(),
            fondsId: backOfficeState?.fondsId!,
            searchProjectName: searchProjectName,
            searchPlatformName: searchPlatformName,
            offset: offset,
            limit: limit,
        },
        {
            skip:
                dateChosen === undefined ||
                backOfficeState?.fondsId === undefined
                    ? true
                    : false || limit > 0
                      ? false
                      : true,
        }
    );

    const projectValorisations = projectValorisationsWithNbItems?.rows;
    const nbItems = projectValorisationsWithNbItems?.count;

    const { data: valorisationProject } = useGetValorisationByProjectQuery(
        {
            dateStart: (dateChosen ? dateChosen : new Date())?.toISOString(),
            dateEnd: (dateChosen ? dateChosen : new Date())?.toISOString(),
        },
        {
            skip: dateChosen === undefined ? true : false,
        }
    );

    useEffect(() => {
        if (searchPlatformName !== '' || searchProjectName !== '') return;
        if (projectValorisations && dateChosen) {
            const allValo = projectValorisations
                ?.map((val) => val.valorisation)
                ?.flat();

            const bool =
                allValo?.filter((val) =>
                    isDateClosed(new Date(val?.date), dateChosen!)
                ).length > 0;
            if (bool) {
                setIsPending('shareComputed');
            } else {
                setIsPending('shareNotComputed');
            }
        }
    }, [projectValorisations, isLoadingValo, isFetchingValo]);

    const [projectsDataFiltered, setProjectsDataFiltered] = useState<
        ProjectWithValorisationDto[]
    >([]);

    useEffect(() => {
        if (projectValorisations) {
            filtrationFunction();
        }
    }, [filterColumn, projectValorisations, isFetchingValo, offset, limit]);

    const filtrationFunction = () => {
        let transactionDataFiltered_init: ProjectWithValorisationDto[] =
            projectValorisations ?? [];
        for (const item of filterColumn) {
            transactionDataFiltered_init = filterFunctions(
                transactionDataFiltered_init,
                item.element,
                item.type,
                item.value,
                platformsData
            );
        }
        setProjectsDataFiltered(transactionDataFiltered_init);
    };

    // const [valoByProject, setValoByProject] = useState<number>(0);

    const valoByProject = useMemo((): number => {
        if (
            valorisationProject &&
            valorisationProject.length !== 0 &&
            dateChosen
        ) {
            return (
                valorisationProject.find(
                    (val) =>
                        val.fondsId === backOfficeState?.fondsId &&
                        isDateClosed(new Date(val.date), dateChosen)
                )?.value || 0
            );
        } else return 0;
    }, [valorisationProject, backOfficeState?.fondsId, dateChosen]);

    // use queryparam instead of useState fo viewProjects
    const [viewProjectsToggle, setViewProjectsToggle] = useQueryParam(
        'viewProjects',
        BooleanParam
    );

    useEffect(() => {
        if (viewProjectsToggle === undefined) {
            setViewProjectsToggle(true);
        }
    }, []);

    const [valoBool, setValoBool] = useState(false);

    return (
        <React.Fragment>
            <DropDownComponentAdmin
                title="Valorisation summary"
                className="w-full mx-auto md:w-2/3"
                bool={valoBool}
                setBool={setValoBool}
            >
                <ValorisationComponent
                    fondsId={backOfficeState?.fondsId!}
                    dateChosen={dateChosen}
                />
            </DropDownComponentAdmin>
            <div className="flex gap-2">
                <p
                    className={`p-2 h-fit font-titleFontFamily cursor-pointer ${
                        viewProjectsToggle &&
                        'rounded-md bg-secondBackgroundColor text-mainColor shadow-in'
                    }`}
                    onClick={() => setViewProjectsToggle(true)}
                >
                    Table projets
                </p>
                <p
                    className={` p-2 h-fit font-titleFontFamily cursor-pointer ${
                        !viewProjectsToggle &&
                        'rounded-md bg-secondBackgroundColor text-mainColor shadow-in'
                    }`}
                    onClick={() => setViewProjectsToggle(false)}
                >
                    Gestion fermeture projets
                </p>
            </div>
            {/* <div className="w-full py-2 mx-auto md:w-2/3"> */}

            {/* </div> */}

            {viewProjectsToggle && dateChosen ? (
                <>
                    <FilterApparition
                        startIntervalNbItems={offset}
                        setNbItemsShowed={setLimit}
                        nbItemsShowed={limit}
                        setStartIntervalNbItems={setOffset}
                        array={projectValorisations!}
                        offset={true}
                        nbItems={nbItems ?? 0}
                    />
                    <div className="flex flex-col max-w-full overflow-x-auto">
                        <div className="overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full">
                            <div className="py-2 inline-block min-w-full">
                                <div className="overflow-hidden">
                                    <table className="min-w-full">
                                        <thead className="bg-white border-b border-t">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className={`${classNameObejct.head} `}
                                                >
                                                    #
                                                </th>
                                                <FiltrationColumnTable
                                                    element={'name'}
                                                    type={typeFiltration.name}
                                                    setFilterColumn={
                                                        setFilterColumn
                                                    }
                                                    setSearch={
                                                        setSearchProjectName
                                                    }
                                                    columnName={() => (
                                                        <p className="inline-block">
                                                            Nom
                                                        </p>
                                                    )}
                                                    orderOrFiltration={
                                                        typeOrderOrFiltration.filter
                                                    }
                                                    textLeft={true}
                                                />
                                                <FiltrationColumnTable
                                                    element={'platformId'}
                                                    setSearch={
                                                        setSearchPlatformName
                                                    }
                                                    type={
                                                        typeFiltration.nameWithId
                                                    }
                                                    setFilterColumn={
                                                        setFilterColumn
                                                    }
                                                    columnName={() => (
                                                        <p className="inline-block">
                                                            Plateforme
                                                        </p>
                                                    )}
                                                    orderOrFiltration={
                                                        typeOrderOrFiltration.filter
                                                    }
                                                    textLeft={true}
                                                />

                                                <th
                                                    scope="col"
                                                    className={
                                                        classNameObejct.head
                                                    }
                                                >
                                                    Rendement
                                                </th>

                                                <th
                                                    scope="col"
                                                    className={
                                                        classNameObejct.head
                                                    }
                                                >
                                                    Montant actuel
                                                </th>
                                                <th
                                                    scope="col"
                                                    className={
                                                        classNameObejct.head
                                                    }
                                                >
                                                    Montant initial
                                                </th>
                                                <th
                                                    scope="col"
                                                    className={
                                                        classNameObejct.head
                                                    }
                                                >
                                                    Proportion du <br />
                                                    portefeuille
                                                </th>
                                                <FiltrationColumnTable
                                                    element={'dateStart'}
                                                    type={typeFiltration.date}
                                                    setFilterColumn={
                                                        setFilterColumn
                                                    }
                                                    columnName={() => (
                                                        <p className="inline-block">
                                                            Date début
                                                        </p>
                                                    )}
                                                    orderOrFiltration={
                                                        typeOrderOrFiltration.order
                                                    }
                                                    textLeft={true}
                                                />
                                                <FiltrationColumnTable
                                                    element={'dateEnd'}
                                                    type={typeFiltration.date}
                                                    setFilterColumn={
                                                        setFilterColumn
                                                    }
                                                    columnName={() => (
                                                        <p className="inline-block">
                                                            Date fin
                                                        </p>
                                                    )}
                                                    orderOrFiltration={
                                                        typeOrderOrFiltration.order
                                                    }
                                                    textLeft={true}
                                                />
                                            </tr>
                                        </thead>

                                        <React.Fragment>
                                            <tbody>
                                                {isPending === 'pending' ||
                                                isLoadingValo ||
                                                isLoadingCash ||
                                                isFetchingValo ? (
                                                    <tr className="w-full mx-auto">
                                                        <td
                                                            colSpan={9}
                                                            className="text-center text-gray-500 text-xl  pt-10"
                                                        >
                                                            <Loading />
                                                        </td>
                                                    </tr>
                                                ) : isPending ===
                                                  'shareComputed' ? (
                                                    <>
                                                        {!!(
                                                            backOfficeState?.fondsId !==
                                                                undefined &&
                                                            projectValorisations &&
                                                            cashData &&
                                                            dateChosen
                                                        ) && (
                                                            <React.Fragment>
                                                                {dateChosen &&
                                                                    cashData
                                                                        ?.filter(
                                                                            (
                                                                                val
                                                                            ) =>
                                                                                val.fondsId ===
                                                                                    backOfficeState?.fondsId &&
                                                                                isDateClosed(
                                                                                    new Date(
                                                                                        val.date
                                                                                    ),
                                                                                    dateChosen
                                                                                )
                                                                        )
                                                                        ?.map(
                                                                            (
                                                                                cash,
                                                                                key
                                                                            ) => (
                                                                                <tr
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                    className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 bg-zinc-500
                              } `}
                                                                                >
                                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                                                                                        {/* {key + 2} */}
                                                                                    </td>
                                                                                    <td
                                                                                        className={`${classNameObejct.item} text-white `}
                                                                                    >
                                                                                        Cash
                                                                                    </td>
                                                                                    <td
                                                                                        className={`${classNameObejct.item} text-white `}
                                                                                    ></td>
                                                                                    <td
                                                                                        className={`${classNameObejct.item} text-white `}
                                                                                    ></td>
                                                                                    <td
                                                                                        className={`${classNameObejct.item} text-white`}
                                                                                    >
                                                                                        {printLargeValue(
                                                                                            cash.value.toFixed(
                                                                                                2
                                                                                            )
                                                                                        )}
                                                                                    </td>
                                                                                    <td
                                                                                        className={`${classNameObejct.item} text-white `}
                                                                                    ></td>

                                                                                    <td
                                                                                        className={`${classNameObejct.item} text-white `}
                                                                                    >
                                                                                        {printLargeValue(
                                                                                            (
                                                                                                (cash.value *
                                                                                                    100) /
                                                                                                valoByProject
                                                                                            ).toFixed(
                                                                                                4
                                                                                            )
                                                                                        ) +
                                                                                            ' %'}
                                                                                    </td>
                                                                                    <td
                                                                                        className={`${classNameObejct.item} text-white`}
                                                                                    >
                                                                                        {/* {new Date(project.dateStart).toLocaleDateString()} */}
                                                                                    </td>
                                                                                    <td
                                                                                        className={`${classNameObejct.item} text-white`}
                                                                                    >
                                                                                        {/* {new Date(project.dateEnd).toLocaleDateString()} */}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                {shareData &&
                                                                    shareData.length >
                                                                        0 &&
                                                                    dateChosen &&
                                                                    userFondsId !==
                                                                        undefined &&
                                                                    ShareDataFiltered !==
                                                                        undefined &&
                                                                    ShareDataFiltered?.allInfoUser(
                                                                        dateChosen
                                                                    )?.map(
                                                                        (
                                                                            fondsInfo,
                                                                            key
                                                                        ) => (
                                                                            <ElementTableUserFonds
                                                                                fondsInfo={
                                                                                    fondsInfo
                                                                                }
                                                                                key={
                                                                                    key
                                                                                }
                                                                                dateChosen={
                                                                                    dateChosen
                                                                                }
                                                                                index1={
                                                                                    key
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                {!!(
                                                                    dateChosen &&
                                                                    projectsDataFiltered
                                                                ) && (
                                                                    <>
                                                                        {projectsDataFiltered.length ===
                                                                        0 ? (
                                                                            <tr className="w-full mx-auto">
                                                                                <td
                                                                                    colSpan={
                                                                                        8
                                                                                    }
                                                                                    className="text-center text-gray-500 text-xl  pt-10"
                                                                                >
                                                                                    Aucun
                                                                                    projet
                                                                                    touvée
                                                                                </td>
                                                                            </tr>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                {projectsDataFiltered
                                                                                    ?.filter(
                                                                                        (
                                                                                            val
                                                                                        ) =>
                                                                                            val.fondsId ===
                                                                                                backOfficeState?.fondsId &&
                                                                                            !val.name.includes(
                                                                                                'Cash'
                                                                                            ) &&
                                                                                            (!val.closed ||
                                                                                                (val.closed &&
                                                                                                    compareTwoDate(
                                                                                                        dateChosen,
                                                                                                        new Date(
                                                                                                            val.dateEnd
                                                                                                        )
                                                                                                    )))
                                                                                    )

                                                                                    .map(
                                                                                        (
                                                                                            project,
                                                                                            key
                                                                                        ) => {
                                                                                            return {
                                                                                                name: project.name,
                                                                                                valorisation:
                                                                                                    project.valorisation?.filter(
                                                                                                        (
                                                                                                            val
                                                                                                        ) =>
                                                                                                            isDateClosed(
                                                                                                                new Date(
                                                                                                                    val.date
                                                                                                                ),
                                                                                                                dateChosen
                                                                                                            )
                                                                                                    )?.[0] ?? {
                                                                                                        valorisation: 0,
                                                                                                        initialAmount: 0,
                                                                                                    },
                                                                                                id: project.id,
                                                                                                platformName:
                                                                                                    project.platformName,
                                                                                                interest:
                                                                                                    getInterest(
                                                                                                        project.interests,
                                                                                                        dateChosen
                                                                                                    ),
                                                                                                dateStart:
                                                                                                    project.dateStart,
                                                                                                dateEnd:
                                                                                                    project.dateEnd,
                                                                                            };
                                                                                        }
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            project,
                                                                                            key
                                                                                        ) => (
                                                                                            <tr
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        project.id
                                                                                                    ) {
                                                                                                        navigate(
                                                                                                            `/DashBoardAdmin/ProjectsManage?fonds=${backOfficeState.fondsId}&projectId=${project.id}&projectManage=EDIT&pageEditProject=BasicInfo&returnBackoffice=1`
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                                key={
                                                                                                    key
                                                                                                }
                                                                                                className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                                                                                                    key %
                                                                                                        2 ===
                                                                                                    1
                                                                                                        ? 'bg-gray-100'
                                                                                                        : 'bg-white'
                                                                                                } `}
                                                                                            >
                                                                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                                                    {key +
                                                                                                        1 +
                                                                                                        offset}
                                                                                                </td>
                                                                                                <td
                                                                                                    className={
                                                                                                        classNameObejct.item
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        project.name
                                                                                                    }
                                                                                                </td>
                                                                                                <td
                                                                                                    className={
                                                                                                        classNameObejct.item
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        project.platformName
                                                                                                    }
                                                                                                </td>
                                                                                                <td
                                                                                                    className={
                                                                                                        classNameObejct.item
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        project.interest
                                                                                                    }{' '}
                                                                                                    %
                                                                                                </td>
                                                                                                <td
                                                                                                    className={`${classNameObejct.item}`}
                                                                                                >
                                                                                                    {project.id &&
                                                                                                        printLargeValue(
                                                                                                            project?.valorisation?.valorisation.toFixed(
                                                                                                                2
                                                                                                            )
                                                                                                        )}
                                                                                                </td>
                                                                                                <td
                                                                                                    className={`${classNameObejct.item}`}
                                                                                                >
                                                                                                    {project.id &&
                                                                                                        printLargeValue(
                                                                                                            project?.valorisation?.initialAmount.toFixed(
                                                                                                                2
                                                                                                            )
                                                                                                        )}
                                                                                                </td>
                                                                                                <td
                                                                                                    className={`${classNameObejct.item}`}
                                                                                                >
                                                                                                    {project.id &&
                                                                                                        printLargeValue(
                                                                                                            (
                                                                                                                (project
                                                                                                                    ?.valorisation
                                                                                                                    ?.valorisation *
                                                                                                                    100) /
                                                                                                                valoByProject
                                                                                                            ).toFixed(
                                                                                                                2
                                                                                                            )
                                                                                                        ) +
                                                                                                            ' %'}
                                                                                                </td>
                                                                                                <td
                                                                                                    className={
                                                                                                        classNameObejct.item
                                                                                                    }
                                                                                                >
                                                                                                    {formatDate(
                                                                                                        new Date(
                                                                                                            project.dateStart
                                                                                                        )
                                                                                                    )}
                                                                                                </td>
                                                                                                <td
                                                                                                    className={
                                                                                                        classNameObejct.item
                                                                                                    }
                                                                                                >
                                                                                                    {formatDate(
                                                                                                        new Date(
                                                                                                            project.dateEnd
                                                                                                        )
                                                                                                    )}
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    )}
                                                                            </React.Fragment>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </>
                                                ) : (
                                                    <tr className="w-full mx-auto">
                                                        <td
                                                            colSpan={9}
                                                            className="text-center text-gray-500 text-xl  pt-10"
                                                        >
                                                            <ElementNotComputedComponent element="La valorisation des projets" />
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </React.Fragment>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <ManageClosureProjects dateChosen={dateChosen} />
            )}
        </React.Fragment>
    );
};

export default ViewTableProjects;

function ValorisationComponent({
    dateChosen,
    fondsId,
}: {
    dateChosen: Date | undefined;
    fondsId: number;
}) {
    const { data: valorisationProject } = useGetValorisationByProjectQuery(
        {
            dateStart: (dateChosen ? dateChosen : new Date())?.toISOString(),
            dateEnd: (dateChosen ? dateChosen : new Date())?.toISOString(),
        },
        {
            skip: dateChosen === undefined ? true : false,
        }
    );

    const valoByProject = valorisationProject?.find(
        (v) =>
            isDateClosed(new Date(v.date), dateChosen || new Date()) &&
            v.fondsId === fondsId
    );
    const rows = [
        {
            name: 'Non blackList',
            valo: valoByProject?.nonBlackList,
            proportion: valoByProject
                ? (valoByProject?.nonBlackList * 100) / valoByProject?.value
                : 0,
        },
        {
            name: 'BlackList',
            valo: valoByProject?.blackList,
            proportion: valoByProject
                ? (valoByProject?.blackList * 100) / valoByProject?.value
                : 0,
        },
        {
            name: 'Sans intérets',
            valo: valoByProject?.valoWithoutInterest,
            proportion: valoByProject
                ? (valoByProject?.valoWithoutInterest * 100) /
                  valoByProject?.nonBlackList
                : 0,
        },
        {
            name: 'Total',
            valo: valoByProject?.value,
            proportion: valoByProject
                ? (valoByProject?.value * 100) / valoByProject?.value
                : 0,
        },
    ];
    const classNameObejct = {
        item: 'text-sm font-light px-1 py-2 whitespace-nowrap',
    };
    return (
        <div className="p-2 flex flex-col gap-2">
            <TableComponent head={['', 'Valorisation', 'Proportion %']}>
                <tbody>
                    {valoByProject ? (
                        <>
                            {rows?.map((val, key) => (
                                <tr
                                    className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                                        key % 2 === 1
                                            ? 'bg-secondBackgroundColor'
                                            : 'bg-white '
                                    } `}
                                >
                                    <td
                                        className={`${classNameObejct.item} pl-1`}
                                    >
                                        {val.name}
                                    </td>
                                    <td className={`${classNameObejct.item}`}>
                                        {printLargeValue(val.valo?.toFixed(2))}
                                    </td>
                                    <td className={`${classNameObejct.item}`}>
                                        {val.proportion?.toFixed(2)} %
                                    </td>
                                </tr>
                            ))}
                        </>
                    ) : null}
                </tbody>
            </TableComponent>
        </div>
    );
}

function ElementTableUserFonds({
    fondsInfo,
    index1,
    dateChosen,
}: {
    fondsInfo: IFondsInfoUser;
    index1: number;
    dateChosen: Date;
}) {
    const classNameObejct = {
        head: 'text-sm text-gray-900 px-6 py-4 text-left font-semibold text-right',
        item: 'text-sm font-light px-6 py-4 whitespace-nowrap text-right',
    };

    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <>
            <tr
                key={index1}
                className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                    index1 % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                } `}
                onClick={() => setExpanded(!expanded)}
            >
                {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right">
          {index1 + 1}
        </td> */}
                <td className={`${classNameObejct.item} flex gap-3`}>
                    <ChevronRightIcon
                        aria-hidden="true"
                        className={`w-4 h-4  mx-auto transform transition ease-in-out duration-200 ${
                            expanded ? 'rotate-90' : 'rotate-0'
                        }`}
                    ></ChevronRightIcon>{' '}
                    {fondsInfo.fondsName}
                </td>
                <td className={classNameObejct.item}>
                    {formatDate(fondsInfo.infoCombined.firstDate)}
                </td>

                <td className={classNameObejct.item}>
                    {printLargeValue(fondsInfo.infoCombined.initialAmount)}
                </td>
                <td className={classNameObejct.item}>
                    {printLargeValue(fondsInfo.infoCombined.nbShare.toFixed(2))}{' '}
                    <br />
                </td>
                <td className={classNameObejct.item}>
                    {fondsInfo.infoCombined.initialValueOfShare.toFixed(2)}
                </td>
                <td className={classNameObejct.item}>
                    {printLargeValue(fondsInfo.infoCombined.share.toFixed(2))}
                    <br />
                </td>
                <td className={classNameObejct.item}>
                    {fondsInfo.infoCombined.performances.toFixed(2)} %
                </td>
                <td className={classNameObejct.item}>
                    {fondsInfo.infoCombined.tri.toFixed(2)} %
                </td>
                <td className={classNameObejct.item}>
                    {printLargeValue(
                        (
                            fondsInfo.infoCombined.share *
                            fondsInfo.infoCombined.nbShare
                        ).toFixed(2)
                    )}
                </td>
                <td className={classNameObejct.item}>
                    {formatDate(dateChosen)}
                </td>
            </tr>
            {expanded ? (
                <>
                    {fondsInfo.transactions.map((t, index) => (
                        <tr
                            key={index}
                            className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 bg-gray-300 `}
                        >
                            {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right">
                {index + 1}
              </td> */}
                            <td className={classNameObejct.item}>
                                Transaction {index + 1}
                            </td>
                            <td className={classNameObejct.item}>
                                {formatDate(t.dateStartInvestisseur)}
                            </td>
                            <td className={classNameObejct.item}>
                                {printLargeValue(t.initialAmount)}
                            </td>
                            <td className={classNameObejct.item}>
                                {printLargeValue(t.nbShare.toFixed(2))} <br />
                            </td>
                            <td className={classNameObejct.item}>
                                {t.initialValueOfShare.toFixed(2)}
                            </td>
                            <td className={classNameObejct.item}>
                                {printLargeValue(t.share.toFixed(2))}
                                <br />
                            </td>
                            <td className={classNameObejct.item}>
                                {t.performance.toFixed(2)} %
                            </td>
                            <td className={classNameObejct.item}>
                                {t.tri.toFixed(2)} %
                            </td>
                            <td className={classNameObejct.item}>
                                {printLargeValue(
                                    (t.share * t.nbShare).toFixed(2)
                                )}
                            </td>
                            <td className={classNameObejct.item}>
                                {formatDate(dateChosen)}
                            </td>
                        </tr>
                    ))}
                </>
            ) : null}
        </>
    );
}

function ManageClosureProjects({
    dateChosen,
}: {
    dateChosen: Date | undefined;
}) {
    const backOfficeState = useContext(BackOfficeState);

    const dispatch = useAppDispatch();

    const [filter, setFilter] = useState<FilterClosure>(FilterClosure.open);

    const [searchProjectName, setSearchProjectName] = useState<string>('');
    const [searchPlatformName, setSearchPlatformName] = useState<string>('');

    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(50);

    const { data: projectsWithNbItems, isLoading: isLoadingProjectClosure } =
        useGetProjectsClosureFilterQuery(
            {
                fondsId: backOfficeState?.fondsId ?? 0,
                filter: filter,
                searchProjectName: searchProjectName,
                searchPlatformName: searchPlatformName,
                offset: offset,
                limit: limit,
            },
            {
                skip: !backOfficeState?.fondsId,
            }
        );

    const projects = projectsWithNbItems?.rows;
    const nbItems = projectsWithNbItems?.count;

    const [formClosureToggle, setFormClosureToggle] = useState<boolean>(false);

    const [filterColumn, setFilterColumn] = useState<
        filtrationInterface<ProjectTableClosureDto>[]
    >([
        {
            element: 'dateStart',
            type: typeFiltration.date,
            value: 'decroissant',
            orderOrFiltration: typeOrderOrFiltration.order,
        },
    ]);

    const classNameObejct = {
        head: 'text-sm text-gray-900 px-6 py-4 text-left font-semibold',
        item: 'text-sm font-light px-6 py-4 whitespace-nowrap',
    };

    const [projectsDataFiltered, setProjectsDataFiltered] = useState<
        ProjectTableClosureDto[]
    >([]);

    useEffect(() => {
        if (projects !== undefined) {
            filtrationFunction();
        }
    }, [filterColumn, projects, offset, limit]);

    const { data: platformsData } = useGetAllPlatformsQuery();

    const [queryParam, setQueryParam] = useQueryParam('projectId', StringParam);

    const filtrationFunction = () => {
        let transactionDataFiltered_init: ProjectTableClosureDto[] =
            projects ?? [];
        for (const item of filterColumn) {
            transactionDataFiltered_init = filterFunctions(
                transactionDataFiltered_init,
                item.element,
                item.type,
                item.value,
                platformsData
            );
        }
        setProjectsDataFiltered(transactionDataFiltered_init);
    };

    return (
        <>
            {formClosureToggle ? (
                <FormClosure
                    editToggle={formClosureToggle}
                    setEditToggle={setFormClosureToggle}
                />
            ) : null}
            <div className="flex justify-end gap-3 items-center">
                <h3>Filtrer:</h3>
                <select
                    className="border p-2 rounded-md cursor-pointer"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value as FilterClosure)}
                >
                    <option value="closed">Projet clôturé</option>
                    <option value="open">Projet non clôturé</option>
                    <option value="all">Tous les projets</option>
                </select>
            </div>
            <FilterApparition
                startIntervalNbItems={offset}
                setNbItemsShowed={setLimit}
                nbItemsShowed={limit}
                setStartIntervalNbItems={setOffset}
                offset={true}
                nbItems={nbItems ?? 0}
                array={projects ?? []}
            />
            <div className="flex flex-col max-w-full overflow-x-auto">
                <div className="overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full">
                    <div className="py-2 inline-block min-w-full">
                        <div className="overflow-hidden">
                            {/* <p>
                    Valeur total du fonds{" "}
                    {printLargeValue(
                      projectValorisationInfoData?.valorisationByProjects?.toFixed(
                        2
                      )
                    )}
                  </p> */}
                            <table className="min-w-full">
                                <thead className="bg-white border-b border-t">
                                    <tr>
                                        <th
                                            scope="col"
                                            className={`${classNameObejct.head} `}
                                        >
                                            #
                                        </th>
                                        <FiltrationColumnTable
                                            element={'name'}
                                            type={typeFiltration.name}
                                            setSearch={setSearchProjectName}
                                            setFilterColumn={setFilterColumn}
                                            columnName={() => (
                                                <p className="inline-block">
                                                    Nom
                                                </p>
                                            )}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.filter
                                            }
                                            textLeft={true}
                                        />
                                        <FiltrationColumnTable
                                            element={'platformName'}
                                            setSearch={setSearchPlatformName}
                                            type={typeFiltration.nameWithId}
                                            setFilterColumn={setFilterColumn}
                                            columnName={() => (
                                                <p className="inline-block">
                                                    Plateforme
                                                </p>
                                            )}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.filter
                                            }
                                            textLeft={true}
                                        />

                                        <FiltrationColumnTable
                                            element={'dateStart'}
                                            type={typeFiltration.date}
                                            setFilterColumn={setFilterColumn}
                                            columnName={() => (
                                                <p className="inline-block">
                                                    Date début
                                                </p>
                                            )}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.order
                                            }
                                            textLeft={true}
                                        />
                                        <FiltrationColumnTable
                                            element={'dateEnd'}
                                            type={typeFiltration.date}
                                            setFilterColumn={setFilterColumn}
                                            columnName={() => (
                                                <p className="inline-block">
                                                    Date fin
                                                </p>
                                            )}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.order
                                            }
                                            textLeft={true}
                                        />
                                        <FiltrationColumnTable
                                            element={'amount'}
                                            type={typeFiltration.amount}
                                            setFilterColumn={setFilterColumn}
                                            columnName={() => (
                                                <p className="inline-block">
                                                    Montant
                                                </p>
                                            )}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.order
                                            }
                                            textLeft={true}
                                        />
                                        <th
                                            scope="col"
                                            className={`${classNameObejct.head} `}
                                        >
                                            Projet clôturé
                                        </th>
                                    </tr>
                                </thead>

                                <React.Fragment>
                                    <tbody>
                                        <>
                                            {isLoadingProjectClosure ? (
                                                <tr className="w-full mx-auto">
                                                    <td
                                                        colSpan={7}
                                                        className="text-center text-gray-500 text-xl  pt-10"
                                                    >
                                                        <Loading />
                                                    </td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {!!(
                                                        backOfficeState?.fondsId !==
                                                            undefined &&
                                                        projects &&
                                                        dateChosen
                                                    ) && (
                                                        <React.Fragment>
                                                            {dateChosen &&
                                                                projectsDataFiltered
                                                                    ?.sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) =>
                                                                            new Date(
                                                                                a.dateEnd
                                                                            ).getTime() -
                                                                            new Date(
                                                                                b.dateEnd
                                                                            ).getTime()
                                                                    )

                                                                    .map(
                                                                        (
                                                                            project,
                                                                            key
                                                                        ) => {
                                                                            return {
                                                                                name: project.name,
                                                                                closed:
                                                                                    project.closed ??
                                                                                    false,
                                                                                id: project.id,
                                                                                platformName:
                                                                                    project.platformName,
                                                                                dateStart:
                                                                                    project.dateStart,
                                                                                dateEnd:
                                                                                    project.dateEnd,
                                                                                amount: project.amount,
                                                                            };
                                                                        }
                                                                    )
                                                                    .map(
                                                                        (
                                                                            project,
                                                                            key
                                                                        ) => (
                                                                            <tr
                                                                                onClick={() => {
                                                                                    if (
                                                                                        project.id
                                                                                    ) {
                                                                                        setQueryParam(
                                                                                            project.id.toString()
                                                                                        );
                                                                                        setFormClosureToggle(
                                                                                            true
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                key={
                                                                                    key
                                                                                }
                                                                                className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                                                                                    key %
                                                                                        2 ===
                                                                                    1
                                                                                        ? 'bg-gray-100'
                                                                                        : 'bg-white'
                                                                                } `}
                                                                            >
                                                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                                    {key +
                                                                                        1 +
                                                                                        offset}
                                                                                </td>
                                                                                <td
                                                                                    className={
                                                                                        classNameObejct.item
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        project.name
                                                                                    }
                                                                                </td>
                                                                                <td
                                                                                    className={
                                                                                        classNameObejct.item
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        project.platformName
                                                                                    }
                                                                                </td>

                                                                                <td
                                                                                    className={
                                                                                        classNameObejct.item
                                                                                    }
                                                                                >
                                                                                    {formatDate(
                                                                                        new Date(
                                                                                            project.dateStart
                                                                                        )
                                                                                    )}
                                                                                </td>
                                                                                <td
                                                                                    className={
                                                                                        classNameObejct.item
                                                                                    }
                                                                                >
                                                                                    {formatDate(
                                                                                        new Date(
                                                                                            project.dateEnd
                                                                                        )
                                                                                    )}
                                                                                </td>
                                                                                <td
                                                                                    className={
                                                                                        classNameObejct.item
                                                                                    }
                                                                                >
                                                                                    {project.amount
                                                                                        ? printLargeValue(
                                                                                              project.amount.toFixed(
                                                                                                  2
                                                                                              )
                                                                                          )
                                                                                        : '-'}
                                                                                </td>
                                                                                <td
                                                                                    className={`${classNameObejct.item} ${
                                                                                        project.closed
                                                                                            ? 'text-green-400'
                                                                                            : 'text-red-400'
                                                                                    }`}
                                                                                    // onClick={() => {
                                                                                    //   dispatch(
                                                                                    //     editClosureProject({
                                                                                    //       id: project.id,
                                                                                    //       closed: project.closed ? false : true,
                                                                                    //     })
                                                                                    //   );
                                                                                    // }}
                                                                                >
                                                                                    {project.closed
                                                                                        ? 'Oui'
                                                                                        : 'Non'}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    )}
                                                        </React.Fragment>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    </tbody>
                                </React.Fragment>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function FormClosure({
    editToggle,
    setEditToggle,
}: {
    editToggle: boolean;
    setEditToggle: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const dispatch = useAppDispatch();

    const { showError, showSuccess } = useNotificationContext();

    const { register, handleSubmit, reset, getValues, watch } =
        useForm<UpdateProjectClosureDto>();

    const [projectId, setQueryParam] = useQueryParam('projectId', NumberParam);

    const { data: project, isLoading } = useGetProjectByIdQuery(
        projectId ?? 0,
        {
            skip: projectId === undefined,
        }
    );
    const { data: transactions } = useGetTransactionsProjectQuery(
        projectId ?? 0,
        {
            skip: projectId === undefined,
        }
    );

    const { data: cashIds, isLoading: isLoadingCash } = useGetCashIdsQuery();

    const setDefautlValues = (): void => {
        reset({
            id: project?.id,
            dateEnd: new Date(Date.now()).toJSON().split('T')[0],
            closed: project?.closed,
            valorisation: {
                comment: 'Fermeture du projet',
                valorisation: 0,
                initialAmount:
                    transactions
                        ?.filter((t) => t.type === TypeTransaction.cashProject)
                        ?.reduce((sum, curr) => curr.amount + sum, 0) ?? 0,
                fondsId: project?.fondsId,
                projectId: project?.id,
                interest:
                    project?.interests[project?.interests.length - 1].value,
            },
        });
    };

    useEffect(() => {
        if (project && transactions && projectId) {
            setDefautlValues();
        }
    }, [project, transactions]);

    const [
        editClosureProject,
        { isLoading: isLoadingEdit, isSuccess: isSuccessEdit },
    ] = useUpdateClosureProjectMutation();

    const [addTransaction] = useCreateTransactionMutation();

    const onSubmit = async (data: UpdateProjectClosureDto) => {
        data = {
            ...data,
            dateEnd: new Date(data.dateEnd)?.toISOString(),
            valorisation: {
                ...data.valorisation,
                valorisation: stringToFloat(data.valorisation.valorisation),
            },
        };
        const transaction: CreateTransactionDto = {
            amount: data.valorisation.valorisation,
            date: new Date(data.dateEnd)?.toISOString(),
            from: data.id,
            to: cashIds?.find((v) => v.fondsId === project?.fondsId)?.id!,
            type: TypeTransaction.projectStopCash,
            fondsId: project?.fondsId!,
            status: TransactionStatus.VALIDATED,
        };
        await editClosureProject({
            updateProjectClosureDto: data,
            id: data.id!,
        })
            .unwrap()
            .then(async () => {
                await addTransaction(transaction)
                    .unwrap()
                    .then(() => {
                        setEditToggle(false);
                        showSuccess('Edited', 'Project closed successfully');
                    })
                    .catch(() => {
                        showError('Error', 'Error editing project');
                    });
            })
            .catch(() => {
                showError('Error', 'Error editing project');
            });
    };

    return (
        <>
            <PopUp
                open={editToggle}
                setOpen={setEditToggle}
                title={() => {
                    return (
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Clôturer du projet
                        </h3>
                    );
                }}
                buttonBoolean={false}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formTemplateSingUp"
                >
                    <InputComponent
                        register={register}
                        value="dateEnd"
                        type="date"
                    >
                        <h3>
                            Date fermeture projet{' '}
                            <span className="required">*</span>
                        </h3>
                    </InputComponent>

                    <InputComponent
                        register={register}
                        value={'valorisation.valorisation'}
                        type={'number'}
                        className={{
                            input: 'border-2 border-gray-300 p-2 rounded-lg',
                        }}
                    >
                        <h3>
                            Valorisation <span className="required">*</span>
                        </h3>
                    </InputComponent>

                    <CheckboxComponent
                        register={register}
                        values={[
                            {
                                label: 'Projet fermé',
                                name: 'closed',
                            },
                        ]}
                        watch={watch}
                    />
                    <div className="mt-5 w-full sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 text-center">
                        <WhiteButton
                            onClick={() => {
                                setEditToggle(false);
                            }}
                        >
                            Cancel
                        </WhiteButton>
                        <PrimaryButton type="submit">Edit</PrimaryButton>
                    </div>
                </form>
            </PopUp>
        </>
    );
}
