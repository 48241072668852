import {
    CustomUserFeeDto,
    CustomUserFeeType,
    useGetAllFeesQuery,
    useGetAllTypeInvestisseursForFormQuery,
    useGetCustomUserFeesQuery,
} from '@api/api';
import { PrimaryButton } from '@components/commun/Buttons';
import useDebouncedEffect from '@components/commun/CustomHook/useDebouncedEffect';
import usePaginationTable from '@components/commun/CustomHook/usePaginationTable';
import Loading from '@components/commun/Loading';
import FiltrationColumnTable, {
    typeOrderOrFiltration,
} from '@components/DashboardAdmin/BackOffice/Components/FiltrationColumnTable';
import { WrappingTable } from '@components/DashboardAdmin/BackOffice/Components/TableComponent';
import AddCustomUserFee from '@components/DashboardAdmin/Fees/AddCustomUserFee';
import UpdateCustomUserFee from '@components/DashboardAdmin/Fees/UpdateCustomUserFee';
import { DisplayBinaryVariable } from '@components/DashboardAdmin/Sponsor/SponsorIndex';
import { typeFiltration } from '@interfaces/BackOffice';
import { addDays, formatDate, transformDate } from '@utils/Utils';
import { useState } from 'react';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

const CustomFees = () => {
    const [type, setType] = useQueryParam<CustomUserFeeType | string>('type');
    const [typeInvestisseurId, setTypeInvestisseurId] = useQueryParam(
        'typeInvestisseurId',
        NumberParam
    );
    const [feeId, setFeeId] = useQueryParam('feeId', NumberParam);

    const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
    const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);

    const [userName, setUserName] = useQueryParam('userName', StringParam);

    const [userNameDebounced, setUserNameDebounced] = useState<string>('');

    let date = new Date();
    date = transformDate(date);

    const { data: customFeesRes, isLoading: isLoadingCustomFees } =
        useGetCustomUserFeesQuery({
            limit: nbItemsShowed,
            offset: startIntervalNbItems,
            userName: userNameDebounced,
            dateStart: addDays(date, -4 * 365)?.toISOString(),
            dateEnd: new Date(2200, 0, 1)?.toISOString(),
            type: type ? (type == 'all' ? '' : type) : '',
            feeId: feeId || undefined,
            typeInvestisseurId: typeInvestisseurId || undefined,
        });

    useDebouncedEffect(
        () => {
            setUserNameDebounced(userName || '');
        },
        [userName],
        400
    );

    const { data: fees } = useGetAllFeesQuery();

    const { data: typeInvestisseurs } =
        useGetAllTypeInvestisseursForFormQuery();

    const customFees = customFeesRes?.rows || [];
    const nbItems = customFeesRes?.count;

    const { ComponentFilter, dataFiltered, setFilterColumn } =
        usePaginationTable<CustomUserFeeDto>({
            initialOrder: 'dateValidaty',
            data: customFees || [],
            nbItemsShowed: nbItemsShowed,
            startIntervalNbItems: startIntervalNbItems,
            setNbItemsShowed: setNbItemsShowed,
            setStartIntervalNbItems: setStartIntervalNbItems,
        });

    const classNameObejct = {
        head: 'text-sm text-gray-900 px-6 py-4 text-left font-semibold text-left',
        item: 'text-sm font-light px-6 py-4 whitespace-nowrap  text-right',
    };

    const [toggleEdit, setToggleEdit] = useState(false);
    const [toggleAdd, setToggleAdd] = useState(false);

    return (
        <div className="mt-4">
            {toggleEdit ? (
                <UpdateCustomUserFee
                    open={toggleEdit}
                    setOpen={setToggleEdit}
                />
            ) : null}

            {toggleAdd ? (
                <AddCustomUserFee open={toggleAdd} setOpen={setToggleAdd} />
            ) : null}

            <div className="flex justify-center items-center my-6">
                <PrimaryButton onClick={() => setToggleAdd(true)}>
                    Add custom fee
                </PrimaryButton>
            </div>

            <ComponentFilter offset={true} nbItems={nbItems ?? 0}>
                <></>
            </ComponentFilter>
            <WrappingTable>
                <thead className="bg-white border-b border-t ">
                    <tr>
                        <th scope="col" className={classNameObejct.head}>
                            #
                        </th>
                        <FiltrationColumnTable
                            element={'userName'}
                            type={typeFiltration.name}
                            setFilterColumn={setFilterColumn}
                            setSearch={setUserName}
                            columnName={() => (
                                <p className="inline-block">User name</p>
                            )}
                            orderOrFiltration={typeOrderOrFiltration.filter}
                            textLeft={true}
                        />

                        <FiltrationColumnTable
                            element={'typeInvestisseurId'}
                            setSearch={setTypeInvestisseurId}
                            search={typeInvestisseurId}
                            type={typeFiltration.exact}
                            setFilterColumn={setFilterColumn}
                            orderOrFiltration={typeOrderOrFiltration.filter}
                            optionValues={[
                                {
                                    id: 0,
                                    name: 'all',
                                },
                            ]
                                .concat(typeInvestisseurs || [])
                                .map((fee) => ({
                                    value: fee.id,
                                    label: fee.name,
                                }))}
                            columnName={() => (
                                <p className="inline-block ">
                                    Type investisseur
                                </p>
                            )}
                            textLeft={true}
                        />

                        <FiltrationColumnTable
                            element={'feeId'}
                            setSearch={setFeeId}
                            search={feeId}
                            type={typeFiltration.exact}
                            setFilterColumn={setFilterColumn}
                            orderOrFiltration={typeOrderOrFiltration.filter}
                            optionValues={[
                                {
                                    id: 0,
                                    name: 'all',
                                },
                            ]
                                .concat(fees || [])
                                .map((fee) => ({
                                    value: fee.id,
                                    label: fee.name,
                                }))}
                            columnName={() => (
                                <p className="inline-block ">Frais</p>
                            )}
                            textLeft={true}
                        />

                        <th scope="col" className={classNameObejct.head}>
                            Value
                        </th>

                        <th scope="col" className={classNameObejct.head}>
                            Used
                        </th>

                        <FiltrationColumnTable
                            element={'dateValidaty'}
                            type={typeFiltration.date}
                            setFilterColumn={setFilterColumn}
                            columnName={() => (
                                <p className="inline-block text-center">
                                    Date validité (Find)
                                </p>
                            )}
                            orderOrFiltration={typeOrderOrFiltration.order}
                            textLeft={true}
                        />

                        <FiltrationColumnTable
                            element={'type'}
                            setSearch={setType}
                            search={type}
                            type={typeFiltration.exact}
                            setFilterColumn={setFilterColumn}
                            orderOrFiltration={typeOrderOrFiltration.filter}
                            value={['all'].concat(
                                Object.values(CustomUserFeeType).map((v) => v)
                            )}
                            columnName={() => (
                                <p className="inline-block ">Type</p>
                            )}
                            textLeft={true}
                        />
                    </tr>
                </thead>
                <tbody className="bg-white ">
                    {isLoadingCustomFees ? (
                        <tr className="">
                            <td
                                colSpan={8}
                                className="pt-10 text-center mx-auto"
                            >
                                <Loading />
                            </td>
                        </tr>
                    ) : (
                        <>
                            {dataFiltered && (
                                <>
                                    {dataFiltered.length === 0 ? (
                                        <tr className="w-full mx-auto">
                                            <td
                                                colSpan={8}
                                                className="text-center text-gray-500 text-xl  pt-10"
                                            >
                                                Aucun frais trouvés
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {dataFiltered.map((c, key) => (
                                                <RowElement
                                                    c={c}
                                                    key={key}
                                                    number={key}
                                                    setOpen={setToggleEdit}
                                                />
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </tbody>
            </WrappingTable>
        </div>
    );
};

export default CustomFees;

function RowElement({
    c,
    number,
    setOpen,
}: {
    c: CustomUserFeeDto;
    number: number;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const classNameObejct = {
        item: 'text-sm  font-light px-6 py-4 whitespace-nowrap',
    };
    const [customUserFee, setQueryParam] = useQueryParam(
        'customUserFeeId',
        NumberParam
    );
    return (
        <tr
            className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                number % 2 === 0 ? 'bg-secondBackgroundColor' : 'bg-white '
            } `}
            onClick={() => {
                setQueryParam(c.id);
                setOpen(true);
            }}
        >
            <td className={`${classNameObejct.item}`}>{number + 1}</td>
            <td className={`${classNameObejct.item}`}>{c.userName}</td>
            <td className={`${classNameObejct.item}`}>
                {c.typeInvestisseurName}
            </td>

            <td className={`${classNameObejct.item}`}>{c.feeName}</td>
            <td className={`${classNameObejct.item}`}>{c.value}</td>
            <td className={`${classNameObejct.item}`}>
                {c.type === CustomUserFeeType.ONCE ? (
                    <DisplayBinaryVariable bool={!!c.subscriptionUserFondsId} />
                ) : (
                    '-'
                )}
            </td>

            <td className={`${classNameObejct.item} text-center `}>
                {c.dateValidaty ? formatDate(new Date(c.dateValidaty)) : '-'}
            </td>
            <td className={classNameObejct.item}>{c.type}</td>
        </tr>
    );
}
